import { HOST_SITE_ENDPOINTS } from "./base";

const AUTH = {
    LOGIN: HOST_SITE_ENDPOINTS + "/login/",
    //LOGIN: "https://a724d7ee.ngrok.io/login"
    RESET_PASSWORD: HOST_SITE_ENDPOINTS + "/password/reset/request",
    CHANGE_PASSWORD: HOST_SITE_ENDPOINTS + "/password/reset",
    CURRENT_USER: HOST_SITE_ENDPOINTS + "/auth/user/",
    PAYMENT_TYPE: HOST_SITE_ENDPOINTS + "/auth/payment-type",
    PAYMENT_ERROR_TYPE: HOST_SITE_ENDPOINTS + "/auth/payment-type-error",
    REFRESH_TOKEN: HOST_SITE_ENDPOINTS + "/auth/refresh_token"
};

const THEME = {
    GET: HOST_SITE_ENDPOINTS + "/configuration/getConfigurationTheme"
};

const ANALITYCS = {
    STATUS_OVER_TIME: HOST_SITE_ENDPOINTS + "/auth/transactions/analitycs/status_time",
    STATUS_MONEY: HOST_SITE_ENDPOINTS + "/auth/transactions/analitycs/status",
    STATUS_REJECT: HOST_SITE_ENDPOINTS + "/auth/transactions/analitycs/status_reject",
    STATUS_APP_CODE: HOST_SITE_ENDPOINTS + "/auth/transactions/analitycs/status_app_code",
    STATUS_CARRIER: HOST_SITE_ENDPOINTS + "/auth/transactions/analitycs/status_carrier",
    TRANSACTION_ACTIVITY: HOST_SITE_ENDPOINTS + "/auth/transactions/analitycs/activity",
};

const TRANSACTIONS = {
    TABLE_TRANSACTIONS: HOST_SITE_ENDPOINTS + "/auth/transactions/",
    REFUND_TRANSACTION: HOST_SITE_ENDPOINTS + "/auth/transaction/",
    LOG_TRANSACTION: HOST_SITE_ENDPOINTS + "/auth/transaction/",
    HISTORY_STATUS: HOST_SITE_ENDPOINTS + "/auth/transaction/history/log/",
    PDF_TRANSACTION:HOST_SITE_ENDPOINTS + "/auth/transaction/",
}

const REPORTS = {
    REPORT_TRANSACTIONS: HOST_SITE_ENDPOINTS + "/auth/transactions-report",
    REPORT_STATUS: HOST_SITE_ENDPOINTS + "/auth/transactions-report/status"
}

const LINKTOPAY = {
  FORM_LINKTOPAY: HOST_SITE_ENDPOINTS + "/auth/transactions/linktopay",
  LINKTOPAY_MASSIVE: HOST_SITE_ENDPOINTS + "/auth/transactions/linktopay/importer",
  DETAILS_PAYMENT_REFERENCE: HOST_SITE_ENDPOINTS + "/auth/transaction/"
}

const STATUS = {
    SERVER_STATUS: HOST_SITE_ENDPOINTS + "/auth/server/status"
}

const LTPREDEBAN = {
    LTP_REDEBAN: HOST_SITE_ENDPOINTS + "/auth/configuration/linktopay"
}

const REGISTRORDB = {
  TYPE_COMMERCERDB: HOST_SITE_ENDPOINTS + "/catalog/type-commerce",
  TYPE_DOCRDB: HOST_SITE_ENDPOINTS + "/catalog/type-doc",
  LIST_DEPTODRB: HOST_SITE_ENDPOINTS + "/catalog/state", // Trae la lista de departamentos
  GET_CITIES: HOST_SITE_ENDPOINTS + "/catalog-city",
  GET_QUESTIONS: HOST_SITE_ENDPOINTS + "/redeban/get-questions", //post
  VALIDATE_QUESTIONS: HOST_SITE_ENDPOINTS + "/redeban/review-questions", //post
  REGISTER_USER: HOST_SITE_ENDPOINTS + "/redeban/user/",
  CATEGORY: HOST_SITE_ENDPOINTS + "/catalog/category",
  BILLING: HOST_SITE_ENDPOINTS + "/catalog/billing",
  TYPE_PHONE: HOST_SITE_ENDPOINTS + "/catalog/type-phone",
  BANK: HOST_SITE_ENDPOINTS + "/catalog/bank",
  TYPE_ACCOUNT: HOST_SITE_ENDPOINTS + "/catalog/type-account",
  UPLOAD_FILE: HOST_SITE_ENDPOINTS + "/file/upload/", //post
  REFERIDO: HOST_SITE_ENDPOINTS + "/catalog/referido",
}

export const ENDPOINTS = {
    AUTH,
    THEME,
    ANALITYCS,
    TRANSACTIONS,
    REPORTS,
    LINKTOPAY,
    STATUS,
    LTPREDEBAN,
    REGISTRORDB
};