import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

export class AuthRouter extends React.Component {
  render() {
    const { redux, authTable } = this.props;
    let router = null;
    let defaultRouter = <Route {...this.props} />;
    if (!!authTable) {
      if (authTable.login) {
        //console.log(redux)
        if (redux.auth.token) {
          router = defaultRouter;
        } else {
          router = <Redirect to="/ingreso" />;
        }
      }
    } else {
      router = defaultRouter;
    }
    return router;
  }
}

const mapStateToProps = state => ({
  redux: { ...state }
});

export const AuthRouterConnected = connect(mapStateToProps)(AuthRouter);
