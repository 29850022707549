import { ACTION_TYPES } from "../../constant/actionTypes";

export const initialState = {
  expire: null,
  code: null,
  token: null,
  loading: false,
  user: {}
};

export const AuthReducer = function(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.AUTH.SET_STATE:
      return { ...state, ...action.payload };
    case ACTION_TYPES.AUTH.WORKER.LOAD_CURRENT_ACCOUNT:
      return { ...state, ...action.payload };
    case ACTION_TYPES.AUTH.WORKER.LOAD_LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
