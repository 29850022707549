import { ACTION_TYPES } from "../../constant/actionTypes";

/**
 * Action to dispatch the login to the server
 * @param {object} param0 data from form typed by the user
 */
export function authLoadAccount({ email, password }, conf = null) {
  return {
    type: ACTION_TYPES.AUTH.ASYNC.LOGIN,
    payload: {
      email,
      password
    },
    conf
  };
}

/**
 * Action to dispatch the account authenticated
 * @param {object} success data from the server
 */
export function authLogin(success) {
  return {
    type: ACTION_TYPES.AUTH.WORKER.LOAD_CURRENT_ACCOUNT,
    payload: {
      ...success
    }
  };
}

/**
 * Action to dispatch the logout
 * @param {object} success data from the server
 */
export function authLogout() {
  return {
    type: ACTION_TYPES.AUTH.WORKER.LOAD_LOGOUT
  };
}

/**
 * Action to dispatch the logout
 * @param {object} success data from the server
 */
export function authRefreshToken() {
  return {
    type: ACTION_TYPES.AUTH.ASYNC.REFRESH_TOKEN
  };
}

/**
 * Action to disptach a blocking action to the Auth actions
 * @param {boolean} status data from the system to prevent extra actions on Auth
 */
export function authLoading(status = false) {
  return {
    type: ACTION_TYPES.AUTH.BASIC.SET_STATE,
    payload: {
      loading: status
    }
  };
}

export function resetPasswordAccount({ email, token }, conf = null) {
  return {
    type: ACTION_TYPES.AUTH.ASYNC.RESET_PASSWORD,
    payload: {
      email,
      token
    },
    conf
  };
}

export function changePasswordAccount({ identifier, password }, conf = null) {
  return {
    type: ACTION_TYPES.AUTH.ASYNC.CHANGE_PASSWORD,
    payload: {
      identifier,
      password
    },
    conf
  };
}