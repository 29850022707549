import { store } from "../../App";
import { authLogout } from "../model/auth/authActions";
import { ENDPOINTS } from "../constant/endPoints";
import Axios from "axios";
var qs = require('qs');

Axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(error.response) {
      const {status, config} = error.response;
      if(status === 401 && !config.url.endsWith("/ingreso/")) {
        store.dispatch(authLogout());
      }
    } else {
      store.dispatch(authLogout());
    }
    return Promise.reject(error);
  });

export const fetchCreateReports = (parametros, token, language, separator) => {
    return fetchReports(`${ENDPOINTS.REPORTS.REPORT_TRANSACTIONS}`, parametros,token,language, separator );
};


export const fetchReports = (url, parametros, token, language, separator) => {
  const paramsLanguage={
    ...parametros,
    language:language,
    separator:separator
  }
  return Axios.get(url,
    {
      headers: {
        Authorization: 'Bearer '.concat(token),
       "Content-Type": "application/json"
      },
      credentials: "same-origin",
      params: paramsLanguage,
      paramsSerializer: function(params) {
       return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    }
  );
};

export const fetchReportStatus = (parametros, token) => {
  return fetchReports(`${ENDPOINTS.REPORTS.REPORT_STATUS}`, parametros,token);
};