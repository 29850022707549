import { setTranslations } from "react-i18nify";

setTranslations({
  en: {
    sign_up: "Sign up",
    log_in: "Login",
    login_message:{
      1: "With this tool, access details of your account transactions and follow the operation minute by minute.",
      2: "Discover the power of information by accessing user behavior graphs in real time. Download reports and find out more about each transaction carried out.",
      3: "Enter your username and password. If you do not already have login details, please contact your provider to request the necessary credentials.",
    },
    loading: "loading",
    welcome_to: "Welcome",
    subtitle: "Transaction Administrator",
    user: "User",
    email: "Email",
    password: "Password",
    new_password: "Enter your new password",
    new_password_size: "Password too short",
    new_password_rule: "Password does not meet required format",
    new_password_same: "Passwords do not match",
    repeat_password: "Confirm your password",
    forgot_password: "I forgot my password",
    rule_password:
      "Remember that your password must be at least 14 characters long and contain lowercase and uppercase letters, numbers and symbols such as !@#$%^&*",
    error_messages: {
      email_invalid: "Invalid email",
      email_required: "Enter an email",
      password_required: "Enter a password",
      unauthorized: "Incorrect username and/or password",
    },
    reset_password: {
      title: "I forgot my password",
      button: "Continue",
      msg1: "Done! Check your inbox",
      msg2: "Email may take a few minutes to arrive",
      msg3: "Check your spam folder",
      error: "Invalid Username",
    },
    change_password: {
      title: "New password",
      titleOk: "Password updated",
      msg1: "Your password has been successfully updated",
      login: "Login",
      error: "Invalid Username",
    },
    menu: {
      home: "Home",
      transaction: "Transactions",
      report: "Reports", linkToPay: "LinkToPay",
      linkToPayRdb: "LinkToPay",
      linkToPayRdbCreate: "Create",
      linkToPayRdbSearch: "Query",
      status: "Status",
      logout: "Logout",
      spanish: "Spanish",
      english: "English",
      portuguese: "Portuguese",
    },
    footer: {
      disclaimer: "Powered by Paymentez"
    },
    home: {
      title: "Transaction Summary", 
      description: "History of active sales and transactions", 
      titleMoney: "Transaction Status",   
      descriptionMoney: "Amount accrued by status",
      titleReject: "Reasons for rejection",
      descriptionReject: "Reasons for rejecting transactions",
      titleCarrier: "Payment Network",
      descriptionCarrier: "Transactions by payment network (total per day)",
      titleAppCode: "Transaction status by business code",
      descriptionAppCode:
      "Approved vs. Rejected (Total by business code)", 
      titleActivity: "Activity time",
      descriptionActivity: "Hourly distribution of transactions",
    },
    label: {
      appCode: "Business Code",  
      commerce: "Business",
      paymentMethod: "Payment method",
      currency: "Currency",
      select: "Select",
      search: "Search",
      date: "Date",
      status: "Status",
      detailStatus: "Status Detail",
      authCode: "Authorization Code",
      amount: "Value",
      day7: "Last 7 days",
      day15: "Last 15 days",
      day30: "Last 30 days",
      format: "MM/DD/YYYY HH:mm",
      formatShort: "MM/DD/YYYY",
      timeZone: "Time Zone",
      transaction: "Transactions",
      columns: "Columns",
      fila: "Lines",
      filters: "Filters",
      busqueda: "Search Dates",
    },
    transactions: {
      titleTable: "Transaction History",
      toShow: "Display",
      pie: "Displaying %{start} to %{to} of %{total} transactions", 
      inittable: "Search to view the results.",
      nodata: "Your search returned no results.", 
      yes: "Yes",
      no: "No",
      historyTransaction:"Transaction",
      columns: {
        appCode: "AppCode",
        date: "Date",
        id: "Transaction ID",
        transaction_status: "Transaction status",
        status: "Status",
        detailStatus: "Status Detail",
        authCode: "Authorization Code",
        amount: "Value",
        currency: "Currency",
        paymentMethod: "Payment Method",
        email: "Email",
        card_type: "Deductible",
        card_bin: "BIN",
        card_number: "Card",
        dev_reference: "Order number",
        message: "Message",
        carrier_code: "Response Code",
      },
      label:{
        statusDetail: "Paid"
      },
      transaction_status:{
        pending: "Pending",
        approved: "Approved",
        cancelled: "Cancelled",
        initiated: "Initiated",
        rejected: "Rejected",
        expired: "Expired"
      },
      trx_status_detail: {
        0: "Waiting for Payment",
        1: "In Analysis",
        2: "Paid Partially",
        3: "Paid",
        4: "In Dispute",
        5: "Overpaid",
        6: "Fraud",
        7: "Refund",
        8: "Chargeback Received",
        9: "Rejected by carrier",
        10: "System Error",
        11: "Rejected by Fraud",
        12: "Rejected by Blacklist",
        13: "Time tolerance",
        14: "Expired Payment Gateway",
        15: "Expired by Carrier",
        16: "Rejected by Payment Gateway",
        17: "Abandoned by Payment Gateway",
        18: "Abandoned by Customer",
        19: "Invalid AuthCode",
        20: "AuthCode expired",
        21: "Payment Gateway Fraud - Pending refund",
        22: "Invalid AuthCode - Pending refund",
        23: "AuthCode expired - Pending refund",
        24: "Payment Gateway Fraud - Refund requested",
        25: "Invalid AuthCode - Refund requested",
        26: "AuthCode expired - Refund requested",
        27: "Merchant - Pending refund",
        28: "Merchant - Refund requested",
        29: "Annulled",
        30: "Sent in closing file",
        31: "Waiting OTP",
        32: "OTP successfully validated",
        33: "OTP not validated",
        34: "Partial refund",
        35: "3DS method requested, waiting to continue",
        36: "3DS challenge requested, waiting CRES",
        37: "Rejected by 3DS",
        38: "In Dispute blacklisted",
        39: "In Dispute notified",
        40: "Chargeback applied",
        41: "Paid carrier",
        42: "Settled",
        43: "Invoiced",
        44: "Refund applied",
        45: "Partial refund applied",
        46: "Refund or Chargeback not notified",
        47: "Failure cpf validation",
        48: "Authenticated by 3DS",
        999: "Default"
    },
    },
    reports: {
      title: "Reports",
      description: "Generated Report History",
      disclaimer:
      "* We suggest using a different decompression tool than the one included in your kit. It is recommended:",
      disclaimerA: "The Unarchiver", 
      disclaimerA1: "(MacOS)",
      disclaimerB: "WinRAR",
      disclaimerB1: "(Windows)",
      columns: {
        id: "Product ID",  
        name: "Report",
        date: "Date",  
        user: "Requested by",
        status: "Status",
        action: "Action",
        password: "Password",
      },
      pie: "Displaying %{start} to %{to} of %{total} requested reports",
      download: "Download",
      detail: "Detail",
      status: {
        error: "Error", 
        ok: "Ready",
        tooLong: "Too long",
        inProgress: "Processing",
      },
    },
    export: {
      title: "Export report",
      reportName: "Report Name", 
      separator: "Separator",
      columns:
      "Check this box for the full column report. Otherwise, only the columns selected in the query will be exported.",
      aviso1:
      "If the report has a large amount of information, creating it may take a few minutes.",  
      export: "Export",
      return: "Back",
      createTitle: "Creating report",
      status: {
        error: "There was a problem generating the report",
        ok: "Your report was generated successfully",
        tooLong:
        "Your report is in the process of being created. You can view the status of the report and download it when ready on the Reports page.",
      },
      returnReports: "Go to Reports",
    },
    refund: {
      title: "Refund", 
      button: "Refund",
      buttonYes: "Yes, refund",
      buttonNo: "No, go back",
      total: "Full refund",
      partial: "Partial refund",
      partialInputLabel: "Enter the amount to be refunded",
      msg: {
        warning: "The amount of %{amount} will be refunded.", 
        question: "Do you confirm the refund of this transaction?",
        chooseType: "What type of refund do you want to make?",     
        maxPartialAmount: "The maximum possible is %{amount}.",
        partialRefundError:
        "Field must be less than %{max} and greater than %{min}.",    
        ok: "Refund successful",     
        error: "Unable to perform refund",
        errorPartialRefund: "Operator does not accept partial refund",
      },
    },
    detail: {
      return: "Back",
      print: "Print",
      status_history: "Status history",
      label: {
        transaction: "Transaction",
        owner_name: "Owner",
        application_code: "Application code",
        carrier_tr_id: "Transaction ID",
        transaction_status: "Transaction status",
        status: "Status",
        status_detail: "Status Detail",
        created_at: "Creation date",
        payment_date: "Authorization date",
        updated_at: "Date of last update",
        dev_reference: "Order number",
        product_description: "Product Description",
        amount: "Value",
        vat: "Tax",
        discount: "Discount",
        usd_amount: "Amount in USD",
        installments: "Installments",
        installments_type: "Type of installments",
        refund_amount: "Refund amount",
        MONTODEDEVOLUCION: "REFUND AMOUNT",
        payment: "Payment",
        payment_method_type: "Payment method",
        carrier: "Carrier",
        commerce_code: "Business Code / PSE Agreement",
        card_type: "Deductible",
        batch: "Batch",
        trace_number: "Trace number",
        pse_cycle: "Cycle",
        card_bin: "Card BIN",
        card_number: "Card",
        card_bank_name: "Bank",
        terminal_code: "Terminal",
        ENTIDAD: "ENTITY",
        authorization_code: "Authorization code",
        carrier_code: "Response code",
        message: "Message",
        user: "User",
        user_id: "User ID",
        buyer_full_name: "Full name",
        buyer_email: "email",
        TELEFONO: "PHONE",
        buyer_fiscal_number: "Buyer ID",
        DIRECCION_IP: "IP ADDRESS",
        d: "BILLING ADDRESS",
        c: "BILLING CITY",
        d2: "DELIVERY ADDRESS",
        c2: "DELIVERY CITY",
        authentication: "Authentication",
        commerce: "Business",
        buy: "Buy",
        three_ds: {
          status: "Status",
          return_code: "Response code",
          return_message: "Message", 
          version: "Version",
          xid: "XID",
          cavv: "CAVV",
          eci: "ECI",
        },
        address_billing: {
          address: "Billing address",
          city: "Billing city",
        },
        address_shipping: {
          address: "Delivery address",   
          city: "Delivery city",
        },
        antifraud_title: "Anti-Fraud System",
        antifraud: {
          decision: "Response",
          risk_score: "Risk score",
          rules_triggered: "Rules applied",
          system: "System",
        },
      },
    },
    log: {
      button: "View logs",  
      noData: "No information",
    },
    filters: {
      simpleFilters: "Select a filter and enter a value", 
      multiFilters: 
      "You can perform multiple searches by separating the values with commas ','",
      title: "Filters",
      error: {
        vacio: "No valid data",
        multiple: "Entering more than one value for this filter is not permitted",
        email: "Data with incorrect format",
      },
      authCode: "Auth Code",
      userName: "Name",
      userEmail: "email",
      status: "Status",
      id: "Transaction ID",
      cardType: "Card Type",
      cardBin: "BIN",
      cardNumber: "Card",
      devReference: "Order number",
      userDashboard: "User",
      carrierCode: "Response Code",
      updated_at: "Update",
      created_at:"Creation",
      payment_date:"Payment",
    },
    status: {
      ok: "Approved",   
      error: "Rejected", 
      pending: "Pending",
      title: "Service Status",
      description: "Service Status",
      columns: {
        name: "Service",  
        status: "Status",
        description: "Details",
      },
      pie: "Displaying %{start} to %{to} of %{total} records",
    },
    linktopay: {
      search: {
      titleTable: "LinkToPay History",
      },
      title: "Create Payment Link",
      titleRdb: "Payment Link",
      tabManual: "Insert Manually",
      tabFile: "Load file",
      label: {
        point: "Point (App code)", 
        idUser: "User ID",  
        nameClient: "Client Name",
        lastnameClient: "Client Last Name",
        nameClientRdb: "Who you are sending the link to",
        lastnameClientRdb: "Client Last Name",
        emailClient: "Client Email",
        exampleEmail: "name@provider.com",
        exampleEmailRdb: "abc@abc.com (where the payment link will be sent)",
        phone: "Client’s landline or cell phone", 
        prhoneRdb: "Your client’s landline or cell phone number", 
        phonePlaceholderRdb: "Do not include spaces - in the case of a landline, please inform",
        numOrder: "Order number",      
        numOrderRdb: "Invoice or reference number",
        descripOrder: "Order description",
        descripOrderRdb: "Includes relevant purchase information - Max. 200 characters", 
        amountOrder: "Amount before tax", 
        amountOrderRdb: "Account base amount without tax", 
        iva: "VAT",
        tax: "Tax",
        no: "No",
        yes:"Yes",
        acceptFees: "Accept installments",
        partialPayments: "Partial Payments",
        totalPay: "Total amount payable",
        totalPayRdb: "Total amount of the sale",
        linkExpiration: "Link Validity",
        linkExpirationRdb: "Link Validity",
        successUrl: "Transaction link created successfully",
        rejectUrl: "Invalid transaction link",
        pendingUrl: "Pending transaction link",
        revisionUrl: "Transaction link under review",
        emailUser: "User email",
        nameUser: "Username",
        fiscalNumber: "Type and ID number (optional)",
        fiscalNumberPlaceholder: "ID number",
      },
      tooltip: {
        rule1: "Do not include spaces.",  
        rule2: "In the case of a landline, please inform", 
        rule3: "Must contain at least 10 digits.",
      },
      text: {
        title: "Link Generation",       
        description:        
        "To create a payment link manually, enter the information in the form below.",        
        descriptionRdb: "Generate a new Payment Link",        
        redirect: "Redirect",        
        additionalInfo: "Additional Information",        
        failureData:        
        "Could not generate a link. Please review the data and try again, or contact support",        
        titleModalLoading: "Processing",
        titleModalSuccess: "Link created successfully",
        titleModalFailure: "Link not completed",
        bodyModalSuccess: 
        "The link was generated successfully. You can find it at: ", 
        bodyModalFailure: "Check the data", 
        copySuccess: "Copied!",
        ok: "Success!",
        mensajeListo: 
        "Your new payment link has been successfully generated and an email has been sent to your client", 
        mensaje: "Keep bringing your business to your clients", 
        nuevo: "Generate a new link",
        mensajeLink:
        "To charge, copy this link and send it to your client",
        msajeRedes: "Send the link through social networks",
        titleDetails: "Payment Link Information",
        createdAt: "Creation date",
        emailUser: "Email",
        mind:"Keep in mind",
        clientMail:"Your customer will receive an email from Pagos Redeban to see an example click ",
        clientSpam: "The mail may arrive in the spam folder.",
        here:"Here",
      },
      fiscalNumberTypes: {
        cc: "CC (Citizen ID)",    
        ce: "CE (Foreigner ID)", 
        nit: "NIT (Tax Identification Number)",  
        ti: "TI (ID Card)", 
        pp: "PP (Passport)",
        idc: "IDC (Unique Client Identifier)",
        cel: "CEL",
        rc: "RG (National Identity Card)",
        de: "DE (Foreign Identification Document)",
      },
      tabs: {
        manually: "Create manually",    
        uploadFile: "Upload .csv file",
      },
      button: {
        createLink: "Continue",  
        createLinkRdb: "Create link",
        clearData: "Clear Data",
        closeModal: "Back",
      },
      file: {
        uploadSuccess: "Data uploaded successfully",  
        uploadError: "Upload Error",
        creatingLinks: "Creating payment links",
        linksSuccess: "Batch loading of Payment Links was successful",
        linksErrors: "Check the entered data", 
        formatLinkToPay: "Payment Link Format",
        exampleFile: "Example File",
        preview: "Preview",
        reference: "Order number", 
        customerName: "Customer Name",  
        customerLastName: "Customer Last Name",
        email: "Email",
        phone: "Phone",
        description: "Description",
        amount: "Base amount",
        tax: "Tax",
        expirationDate: "Expiration Date",
        clear: "Clear",
        uploadFile: "Upload file",
        closeModal: "Close",
        id_user: "User ID",
        labelInput: "Invoice file",
      },
      feedback: {
        required: "This field is required", 
        email: "Please enter a valid email",
        minphone: "Must contain at least 10 digits",
        maxphone: "Must not exceed 15 digits",
        positive: "Must be a positive number",
        min: "Must be greater than or equal to 0",
        min1: "Must be greater than or equal to 1",
      },
      columns: {
        CreatedAt: "Created",  
        user_email: "Email", 
        user_name: "Name",
        user_last_name: "Surname",
        order_dev_reference: "Order number",
        order_amount: "Amount",
        payment_url: "Payment URL",
        config_expiration_date: "Expiration date",
        status: "Status",
        payment_reference: "Payment Reference",
        details: "View detail",
        user_creator: "Created by",
        detail_error: "Error detail",
        id_link_to_pay: "Payment Link ID",
        accept_terms: "Terms Accepted",
      },
    },
    calendar:{
      format: "MM/DD/YYYY",
      applyLabel: "Apply",
      cancelLabel: "Cancel",
      fromLabel: "From",
      toLabel: "To",
      customRangeLabel: "Custom" ,
      daysOfWeek:{
        su: "Su",
        mo: "Mo",
        tu: "Tu",
        we: "We",
        th: "Th",
        fr: "Fr",
        sa: "Sa",
      },
      monthNames:{
        jan: "January",
        feb: "February",
        mar: "March",
        apr: "April",
        may: "May",
        jun: " June",
        jul: "July",
        ago: "August",
        sep: "September",
        oct: "October",
        nov: "November",
        dec: "December"
      }
    }
  },
  pt:{
    sign_up: "Registre-se",
    log_in: "Iniciar sessão",
    login_message:{
      1: "Com esta ferramenta, acesse detalhes das transações de suas contas e acompanhe a operação minuto a minuto.",
      2: "Descubra o poder da informação acessando gráficos com o comportamento do usuário em tempo real. Baixe relatórios e confira mais sobre cada transação realizada.",
      3: "Entre com seu nome de usuário e senha. Se ainda não tiver dados de login, fale com seu fornecedor para solicitar as credenciais necessárias.",
    },
    loading: "carregando",
    welcome_to: "Bem-vindo",
    subtitle: "Administrador de transações",
    user: "Usuário",
    email: "E-mail",
    password: "Senha",
    new_password: "Insira sua nova senha",
    new_password_size: "Senha muito curta",
    new_password_rule: "A senha não cumpre o formato exigido",
    new_password_same: "As senhas não correspondem",
    repeat_password: "Confirme sua senha",
    forgot_password: "Esqueci minha senha",
    rule_password:
      "Lembre-se que sua senha deve ter pelo menos 14 caracteres e conter letras minúsculas, maiúsculas, números e símbolos como !@#$%^&* ",
    error_messages: {
      email_invalid: "E-mail inválido",      
      email_required: "Digite um e-mail",     
      password_required: "Insira uma senha",   
      unauthorized: "Usuário e/ou senha incorretos",
    },
    reset_password: {
      title: "Esqueci minha senha",    
      button: "Continuar",   
      msg1: "Pronto! Confira sua caixa de e-mail",   
      msg2: "O e-mail pode levar alguns minutos para chegar",  
      msg3: "Verifique sua caixa de spam", 
      error: "Usuário inválido",
    },
    change_password: {
      title: "Nova senha",   
      titleOk: "Senha atualizada",  
      msg1: "Sua senha foi atualizada com sucesso",
      login: "Fazer login",
      error: "Usuário inválido",
    },
    menu: {
      home: "Início",      
      transaction: "Transações",     
      report: "Relatórios",    
      linkToPay: "Link para pagamento",    
      linkToPayRdb: "Link para pagamento",   
      linkToPayRdbCreate: "Criar",    
      linkToPayRdbSearch: "Consulta",   
      status: "Status",   
      logout: "Sair",
      spanish: "Espanhol",
      english: "Inglês",
      portuguese: "Português",
      
    },
    footer: {
      disclaimer: "Powered by Paymentez"
    },
    home: {
      title: "Resumo de transações",     
      description: "Histórico de transações e vendas ativas",   
      titleMoney: "Status de transações",     
      descriptionMoney: "Valor acumulado por status",   
      titleReject: "Motivos de rejeição", 
      descriptionReject: "Motivos de rejeição das transações", 
      titleCarrier: "Rede de pagamento",
      descriptionCarrier: "Transações por rede de pagamento (total por dia)",
      titleAppCode: "Status das transações por código comercial",  
      descriptionAppCode:     
      "Aprovado x Rejeitado (total por código comercial)",   
      titleActivity: "Horário de atividade",  
      descriptionActivity: "Distribuição horária de transações",
    },
    label: {
      appCode: "Código comercial",  
      commerce: "Comércio",     
      paymentMethod: "Método de pagamento",    
      currency: "Moeda",   
      select: "Selecionar",    
      search: "Buscar",     
      date: "Data",     
      status: "Status",    
      detailStatus: "Detalhe do Status",      
      authCode: "Cod. autorização",     
      amount: "Valor",      
      day7: "Últimos 7 dias",      
      day15: "Últimos 15 dias",      
      day30: "Últimos 30 dias",     
      format: "DD/MM/YYYY HH:mm",     
      formatShort: "DD/MM/YYYY",      
      timeZone: "Fuso horário",      
      transaction: "Transações",     
      columns: "Colunas",     
      fila: "Linhas",     
      filters: "Filtros",     
      busqueda: "Datas de busca",
    },
    transactions: {
      titleTable: "Histórico de transações",    
      toShow: "Exibir",   
      pie: "Exibindo %{start} a %{to} de %{total} transações",   
      inittable: "Faça uma busca para visualizar os resultados.",    
      nodata: "Sua busca não retornou resultados.",      
      yes: "Sim",      
      no: "Não",
      historyTransaction:"Transação",
      columns: {
        appCode: "AppCode",     
        date: "Data",      
        id: "ID de transação", 
        transaction_status: "Status de transação",     
        status: "Status",      
        detailStatus: "Detalhe de status",      
        authCode: "Cod. autorização",      
        amount: "Valor",      
        currency: "Moeda",     
        paymentMethod: "Método de pagamento",     
        email: "E-mail",    
        card_type: "Franquia",   
        card_bin: "BIN",     
        card_number: "Cartão",      
        dev_reference: "Num. do pedido",     
        message: "Mensagem",     
        carrier_code: "Cód de resposta",
      },
      label:{
        statusDetail: "Pagado",
      },
      transaction_status:{
        pending: "Pending",
        approved: "Approved",
        cancelled: "Cancelled",
        initiated: "Initiated",
        rejected: "Rejected",
        expired: "Expired"
      },
      trx_status_detail: {
        0: "Waiting for Payment",
        1: "In Analysis",
        2: "Paid Partially",
        3: "Paid",
        4: "In Dispute",
        5: "Overpaid",
        6: "Fraud",
        7: "Refund",
        8: "Chargeback Received",
        9: "Rejected by carrier",
        10: "System Error",
        11: "Rejected by Fraud",
        12: "Rejected by Blacklist",
        13: "Time tolerance",
        14: "Expired Payment Gateway",
        15: "Expired by Carrier",
        16: "Rejected by Payment Gateway",
        17: "Abandoned by Payment Gateway",
        18: "Abandoned by Customer",
        19: "Invalid AuthCode",
        20: "AuthCode expired",
        21: "Payment Gateway Fraud - Pending refund",
        22: "Invalid AuthCode - Pending refund",
        23: "AuthCode expired - Pending refund",
        24: "Payment Gateway Fraud - Refund requested",
        25: "Invalid AuthCode - Refund requested",
        26: "AuthCode expired - Refund requested",
        27: "Merchant - Pending refund",
        28: "Merchant - Refund requested",
        29: "Annulled",
        30: "Sent in closing file",
        31: "Waiting OTP",
        32: "OTP successfully validated",
        33: "OTP not validated",
        34: "Partial refund",
        35: "3DS method requested, waiting to continue",
        36: "3DS challenge requested, waiting CRES",
        37: "Rejected by 3DS",
        38: "In Dispute blacklisted",
        39: "In Dispute notified",
        40: "Chargeback applied",
        41: "Paid carrier",
        42: "Settled",
        43: "Invoiced",
        44: "Refund applied",
        45: "Partial refund applied",
        46: "Refund or Chargeback not notified",
        47: "Failure cpf validation",
        48: "Authenticated by 3DS",
        999: "Default"
    },
    },
    reports: {
      title: "Relatórios",   
      description: "Histórico de relatórios gerados",  
      disclaimer:  
      "* Sugerimos usar uma ferramenta de descompressão diferente da incluída em seu kit. É recomendado:",  
      disclaimerA: "The Unarchiver",    
      disclaimerA1: "(MacOS)",   
      disclaimerB: "Winrar",  
      disclaimerB1: "(Windows)",
      columns: {
        id: "ID de produto",        
        name: "Relatório",       
        date: "Data",       
        user: "Solicitado por",      
        status: "Status",      
        action: "Ação",     
        password: "Senha",
      },
      pie: "Exibindo %{start} a %{to} de %{total} relatórios solicitados",
      download: "Baixar",
      detail: "Detalhe",
      status: {
        error: "Erro",       
        ok: "Pronto",      
        tooLong: "Muito grande",      
        inProgress: "Processando",
      },
    },
    export: {
      title: "Exportar relatório",     
      reportName: "Nome do relatório", 
      separator: "Separador",   
      columns:   
      "Marque esta caixa para que o relatório saia com todas as colunas. Caso contrário, só serão exportadas as colunas selecionadas na consulta.",  
      aviso1:  
      "Se o relatório tiver um grande volume de informações, sua criação pode levar alguns minutos.",    
      export: "Exportar",   
      return: "Voltar",   
      createTitle: "Criando relatório",
      status: {
        error: "Ocorreu um problema ao gerar o relatório ",       
        ok: "Seu relatório foi gerado com sucesso",    
        tooLong:       
        "Seu relatório está em processo de criação. Você pode ver o status do relatório e baixá-lo quando estiver pronto na página Relatórios.",
      },
      returnReports: "Ir a Relatórios",
    },
    refund: {
      title: "Reembolsar", 
      button: "Reembolsar",      
      buttonYes: "Sim, reembolsar",      
      buttonNo: "Não, voltar",     
      total: "Reembolso total",     
      partial: "Reembolso parcial",    
      partialInputLabel: "Informe o valor a reembolsar",
      msg: {
        warning: "Será reembolsado o valor de %{amount}.",        
        question: "Confirma o reembolso desta transação?",     
        chooseType: "Que tipo de reembolso deseja realizar?",     
        maxPartialAmount: "O máximo possível é %{amount}.",  
        partialRefundError:    
        "O campo deve ser menor que %{max} e maior que %{min}.",      
        ok: "O reembolso foi realizado com sucesso",     
        error: "Não foi possível realizar o reembolso",      
        errorPartialRefund: "A operadora não aceita reembolso parcial",
      },
    },
    detail: {
      return: "Voltar",
      print: "Imprimir",
      status_history:"Histórias do Estado",
      label: {
        transaction: "Transação",        
        owner_name: "Proprietário",        
        application_code: "Código da aplicação",        
        carrier_tr_id: "ID da transação", 
        transaction_status: "Status de transação" ,     
        status: "Status",        
        status_detail: "Detalhe de status",        
        created_at: "Data de criação",       
        payment_date: "Data de pagamento",        
        updated_at: "Data da última atualização",        
        dev_reference: "Número do pedido",        
        product_description: "Descrição do produto",       
        amount: "Valor",        
        vat: "Imposto",       
        discount: "Desconto",       
        usd_amount: "Valor em USD",       
        installments: "Parcelas",        
        installments_type: "Tipo de parcelas",        
        refund_amount: "Valor de reembolso",        
        MONTODEDEVOLUCION: "VALORDEREEMBOLSO",        
        payment: "Pagamento",       
        payment_method_type: "Método de pagamento",       
        carrier: "Operadora",        
        commerce_code: "Código comercial / Convênio PSE",       
        card_type: "Franquia",      
        lote: "Lote",       
        trace_number: "Número de rastreio",       
        pse_cycle: "Ciclo",        
        card_bin: "BIN do cartão",        
        card_number: "Cartão",       
        card_bank_name: "Banco",
        terminal_code: "Terminal",        
        ENTIDAD: "ENTIDADE",       
        authorization_code: "Cód. de autorização",       
        carrier_code: "Cód. de resposta",        
        message: "Mensagem",        
        user: "Usuário",        
        user_id: "ID de usuário",        
        buyer_full_name: "Nome completo",       
        buyer_email: "e-mail",       
        TELEFONO: "TELEFONE",       
        buyer_fiscal_number: "Identificação do comprador",        
        DIRECCION_IP: "ENDEREÇO DO IP",       
        d: "ENDEREÇO DE COBRANÇA",        
        c: "CIDADE DE COBRANÇA",        
        d2: "ENDREÇO DE ENTREGA",        
        c2: "CIDADE DE ENTREGA",        
        autentication: "Autenticação",       
        commerce: "Comércio",       
        buy: "Compra",
        three_ds: {
          status: "Status",       
          return_code: "Código de resposta",      
          return_message: "Mensagem",      
          version: "Versão",    
          xid: "XID",     
          cavv: "CAVV",    
          eci: "ECI",
        },
        address_billing: {
          address: "Endereço de cobrança",      
          city: "Cidade de cobrança",
        },
        address_shipping: {
          address: "Endereço de entrega",      
          city: "Cidade de entrega",
        }, 
        antifraud_title: "Sistema Antifraude",
        antifraud: {
          decision: "Resposta",   
          risk_score: "Score de risco",        
          rules_triggered: "Regras aplicadas",        
          system: "Sistema", 
        },  
      },
    },
    log: {
      button: "Ver registros",      
      noData: "Sem informação ",
    },
    filters: {
      simpleFilters: "Selecione um filtro e digite um valor",    
      multiFilters:   
      "Você pode fazer várias pesquisas separando os valores com vírgulas ','",    
      title: "Filtros",
      error: {
        vacio: "Sem dados válidos",        
        multiple: "Não é permitido inserir mais de um valor para este filtro",       
        email: "Dados com formato incorreto",
      },
      authCode: "Cód. Auth",
      userName: "Nome",
      userEmail: "e-mail",
      status: "Status",
      id: "ID da transação",
      cardType: "Franquia",
      cardBin: "BIN",
      cardNumber: "Cartão",
      devReference: "Número de pedido",
      userDashboard: "Usuário",
      carrierCode: "Cód. de resposta",
      updated_at: "Atualização",
      created_at:"Criação",
      payment_date:"Pagamento",
    },
    status: {
      ok: "Aprovada",     
      error: "Rejeitada",     
      pending: "Pendente",     
      title: "Status de serviços",     
      description: "Status de serviços",
      columns: {
        name: "Serviço",   
        status: "Status",      
        description: "Detalhes",
      },
      pie: "Exibindo %{start} a %{to} de %{total} registros",
    },
    linktopay: {
      search: { 
        titleTable: "Histórico de linkToPay",
      },
      title: "Criação de Link de pagamento",
      titleRdb: "Link de pagamento",
      tabManual: "Inserir Manualmente",
      tabFile: "Carregar arquivo",
      label: {
        point: "Ponto (App code)",       
        idUser: "ID de usuário",       
        nameClient: "Nome do cliente",       
        lastnameClient: "Sobrenome do cliente",       
        nameClientRdb: "Para quem você envia o link",      
        lastnameClientRdb: "Sobrenome do cliente",       
        emailClient: "E-mail do cliente",        
        exampleEmail: "nome@provedor.com",       
        exampleEmailRdb: "abc@abc.com (onde chegará o link de pagamento)",        
        phone: "Telefone fixo ou celular do cliente",       
        prhoneRdb: "Telefone fixo ou celular de seu cliente",        
        phonePlaceholderRdb: "Não inclua espaços - se for fixo, informe",        
        numOrder: "Número de pedido",      
        numOrderRdb: "Número da fatura ou referência",       
        descripOrder: "Descrição do pedido",        
        descripOrderRdb: "Inclui informações relevantes da compra - Máx. 200 caracteres",        
        amountOrder: "Valor antes de impostos",       
        amountOrderRdb: "Valor base da conta sem impostos",       
        iva: "IVA",        
        tax: "Imposto",        
        no: "Não",       
        yes: "Sim",       
        acceptFees: "Aceita parcelamento",        
        partialPayments: "Pagamentos parciais",        
        totalPay: "Valor total a pagar",       
        totalPayRdb: "Valor total da venda",        
        linkExpiration: "Validade do link",        
        linkExpirationRdb: "Validade do link",        
        successUrl: "Link de transação criado com sucesso",        
        rejectUrl: "Link de transação inválido",        
        pendingUrl: "Link de transação pendente",        
        revisionUrl: "Link de transação em revisão",        
        emailUser: "E-mail de usuário",        
        nameUser: "Nome de usuário",        
        fiscalNumber: "Tipo e número de identificação (opcional)",        
        fiscalNumberPlaceholder: "Número de identificação",
      },
      tooltip: {
        rule1: "Não inclua espaços.",    
        rule2: "Se for fixo, informe",       
        rule3: "Deve conter ao menos 10 dígitos.",
      },
      text: {
        title: "Geração de link",     
        description:       
        "Para criar um link de pagamento manualmente, insira as informações no formulário abaixo.",       
        descriptionRdb: "Gerar um novo Link de Pagamento",       
        redirect: "Redirecionamento",     
        additionalInfo: "Informação adicional",       
        failureData:        
        "Não foi possível gerar o link. Revise os dados e tente novamente ou entre em contato com o suporte",       
        titleModalLoading: "Processando",       
        titleModalSuccess: "Link criado com sucesso",        
        titleModalFailure: "Link não concluído",        
        bodyModalSuccess:       
        "O link foi gerado com sucesso. Você pode conferir em: ",        
        bodyModalFailure: "Verifique os dados",        
        copySuccess: "Copiado!",        
        ok: "Sucesso!",       
        mensajeListo:       
        "Seu novo link de pagamento foi gerado com sucesso e o e-mail foi enviado ao seu cliente",        
        mensaje: "Continue levando seu negócio até seus clientes",       
        nuevo: "Gerar um novo link",       
        mensajeLink:       
        "Para fazer cobranças, copie este link e envie para seu cliente",        
        msajeRedes: "Envie o link através das redes sociais",       
        titleDetails: "Informação do Link de pagamento",        
        createdAt: "Data de criação",      
        emailUser: "E-mail",
        mind:"Por favor, note que",
        clientMail:"Seu cliente receberá um e-mail da Pagos Redeban para ver um exemplo clique ",
        clientSpam: "O correio poderia chegar à pasta de spam.",
        here: "Aqui",
      },
      fiscalNumberTypes: {
        cc: "CC (Identificação de cidadão)",      
        ce: "CE (Identificação de estrangeiro)",       
        nit: "NIT (Número de identificação tributária)",       
        ti: "TI (Cartão de identidade)",       
        pp: "PP (Passaporte)",       
        idc: "IDC (Identificador único do cliente)",       
        cel: "CEL",       
        rc: "RG (Registro civil de nascimento)",       
        de: "DE (Documento de identificação estrangeiro)",
      },
      tabs: {
        manually: "Criar manualmente",       
        uploadFile: "Carregar arquivo .csv",
      },
      button: {
        createLink: "Continuar",    
        createLinkRdb: "Criar link",    
        clearData: "Limpar dados",    
        closeModal: "Voltar",
      },
      file: {
        uploadSuccess: "Dados carregados com sucesso",        
        uploadError: "Erro no carregamento",        
        creatingLinks: "Criando links de pagamento",        
        linksSuccess: "O carregamento em lote de Links de Pagamento foi feito com sucesso",        
        linksErrors: "Confira os dados informados",        
        formatLinkToPay: "Formato Link de pagamento",        
        exampleFile: "Exemplo de arquivo",       
        preview: "Pré-visualização",        
        reference: "Número de pedido",        
        customerName: "Nome do Cliente",        
        customerLastName: "Sobrenome do Cliente",       
        email: "e-mail",        
        phone: "Telefone",        
        description: "Descrição",       
        amount: "Valor base",       
        tax: "Imposto",        
        expirationDate: "Data de validade",        
        clear: "Limpar",        
        uploadFile: "Carregar arquivo",        
        closeModal: "Fechar",       
        id_user: "ID de usuário",        
        labelInput: "Arquivo da fatura",
      },
      feedback:{
        required: "Este campo é obrigatório",       
        email: "Informe um e-mail válido",       
        minphone: "Deve conter no mínimo 10 dígitos",        
        maxphone: "Deve ser menor ou igual a 15 dígitos",       
        positive: "Deve ser um número positivo",      
        min: "Deve ser maior ou igual a 0",       
        min1: "Deve ser maior ou igual a 1",
      },
      columns: {
        CreatedAt: "Criado",      
        user_email: "E-mail",       
        user_name: "Nome",
        user_last_name: "Sobrenome",        
        order_dev_reference: "Número do pedido",       
        order_amount: "Valor",       
        payment_url: "Url de pagamento",       
        config_expiration_date: "Data de validade",       
        status: "Status",       
        payment_reference: "Referência de pagamento",       
        details: "Ver detalhe",       
        user_creator: "Criado por",      
        detail_error: "Detalhe do erro",      
        id_link_to_pay: "ID Link de pagamento",      
        accept_terms: "Aceitou os termos",
      },    
    },
    calendar:{
      format: "DD/MM/YYYY HH:mm",
      applyLabel: "Aplicar",
      cancelLabel: "Cancelar",
      fromLabel: "De",
      toLabel: "A",
      customRangeLabel: "Personalizado" ,
      daysOfWeek:{
        su: "Do",
        mo: "Seg",
        tu: "Ter",
        we: "Qua",
        th: "Qui",
        fr: "Sex",
        sa: "Sa",
      },
      monthNames:{
        jan: "Janeiro",
        feb: "Fevereiro",
        mar: "Março",
        apr: "Abril",
        may: "Maio",
        jun: "Junho",
        jul: "Julho",
        ago: "Agosto",
        sep: "Setembro",
        oct: "Outubro",
        nov: "Novembro",
        dec: "Dezembro"
      }
    }
  },
  es: {
    sign_up: "Registrate",
    log_in: "Iniciar sesión",
    login_message: {
      1: "A través de esta herramienta podrás conocer a detalle la transaccionalidad de tus cuentas y hacer seguimiento a la operación minuto a minuto.",
      2: "Descubre el poder de la información accediendo a gráficas con el comportamiento de usuarios en tiempo real. Descarga reportes  y conoce a fondo cada detalle de las transacciones realizadas.",
      3: "Ingresa con tu usuario y clave. Si Aún no cuentas con datos de ingreso, contacta a tu proveedor de servicio para entregarte las credenciales necesarias.",
    },
    loading: "cargando",
    welcome_to: "Bienvenido",
    subtitle: "Administrador de transacciones",
    user: "Usuario",
    email: "Correo electrónico",
    password: "Contraseña",
    new_password: "Ingresa tu nueva contraseña",
    new_password_size: "Contraseña muy corta",
    new_password_rule: "No cumple con el formato",
    new_password_same: "Las contraseñas no son iguales",
    repeat_password: "Confirma la contraseña",
    forgot_password: "Olvidé mi contraseña",
    rule_password:
      "Recuerda que tu contraseña debe ser al menos 14 caracteres y contener minúsculas, mayúsculas, números y símbolos como !@#$%^&* " ,
    error_messages: {
      email_invalid: "Este correo electrónico es invalido",
      email_required: "Un correo electrónico es requerido",
      password_required: "Una clave es necesaria",
      unauthorized: "Usuario y/o password incorrectos",
    },
    reset_password: {
      title: "Olvidé mi contraseña",
      button: "Continuar",
      msg1: "¡Listo! Te hemos enviado un correo",
      msg2: "El correo podría tardar unos minutos en llegar.",
      msg3: "Recuerda revisar la bandeja de correos no deseados.",
      error: "Usuario no valido",
    },
    change_password: {
      title: "Nueva contraseña",
      titleOk: "Contraseña actualizada",
      msg1: "Tu contraseña ha sido actualizada exitosamente",
      login: "Ir al login",
      error: "Usuario no valido",
    },
    menu: {
      home: "Inicio",
      transaction: "Transacciones",
      report: "Reportes",
      linkToPay: "Link de pago",
      linkToPayRdb: "Link de pago",
      linkToPayRdbCreate: "Crear",
      linkToPayRdbSearch: "Consulta",
      status: "Estado",
      logout: "Logout",
      spanish: "Español",
      english: "Inglés",
      portuguese: "Portugués"

    },
    footer: {
      disclaimer: "Powered by Paymentez"
    },
    home: {
      title: "Resumen de transacciones",
      description: "Historial de transacciones y comercios activos",
      titleMoney: "Estado de las transacciones",
      descriptionMoney: "Valor acumulado por estado",
      titleReject: "Tipos de rechazo",
      descriptionReject: "Tipos de rechazo de las transacciones",
      titleCarrier: "Red de pago",
      descriptionCarrier: "Transacciones por red de pago (total por día)",
      titleAppCode: "Estado de transacciones por código de comercio",
      descriptionAppCode:
        "Aprobadas vs Rechazadas (total por código de comercio)",
      titleActivity: "Horario de actividad",
      descriptionActivity: "Distribución horaria de transacciones",
    },
    label: {
      appCode: "Código de Comercio",
      commerce: "Comercio",
      paymentMethod: "Método de pago",
      currency: "Moneda",
      select: "Seleccionar",
      search: "Buscar",
      date: "Fecha",
      status: "Estado",
      detailStatus: "Detalle de estado",
      authCode: "Cod. autoriz",
      amount: "Monto",
      day7: "Últimos 7 días",
      day15: "Últimos 15 días",
      day30: "Últimos 30 días",
      format: "DD/MM/YYYY HH:mm",
      formatShort: "DD/MM/YYYY",
      timeZone: "Zona horaria",
      transaction: "Transacciones",
      columns: "Columnas",
      fila: "Filas",
      filters: "Filtros",
      busqueda: "Datos de búsqueda",
    },
    transactions: {
      titleTable: "Historial de transacciones",
      toShow: "Mostrar",
      pie: "Mostrando %{start} a %{to} de %{total} transacciones",
      inittable: "Realice una búsqueda para visualizar resultados.",
      nodata: "Su búsqueda no produjo resultados.",
      yes: "Sí",
      no: "No",
      historyTransaction:"Transacción",
      columns: {
        appCode: "AppCode",
        date: "Fecha",
        id: "Transaction ID",
        transaction_status: "Estado transacción",
        status: "Estado",
        detailStatus: "Detalle de estado",
        authCode: "Cod. autoriz",
        amount: "Monto",
        currency: "Moneda",
        paymentMethod: "Método de pago",
        email: "Correo",
        card_type: "Franquicia",
        card_bin: "Bin",
        card_number: "Tarjeta",
        dev_reference: "Num. orden",
        message: "Mensaje",
        carrier_code: "Cód de respuesta",
      },
      label:{
        statusDetail: "Pagado",
      },
      transaction_status:{
        pending: "Pendiente",
        approved: "Aprobada",
        cancelled: "Cancelada",
        initiated: "Iniciada",
        rejected: "Rechazada",
        expired: "Expirada"
      },
      trx_status_detail: {
        0: "Esperando Pago",
        1: "Verificacion Requerida",
        2: "Paid Partially",
        3: "Pagado",
        4: "In Dispute",
        5: "Overpaid",
        6: "Fraude",
        7: "Reversado",
        8: "Contracargo",
        9: "Rechazado por el carrier",
        10: "Error de sistema",
        11: "Rechazado por sistema antifraude",
        12: "Rechazado por black list",
        13: "Tiempo de tolerancia",
        14: "Expired Payment Gateway",
        15: "Expired by Carrier",
        16: "Rejected by Payment Gateway",
        17: "Abandoned by Payment Gateway",
        18: "Abandoned by Customer",
        19: "Codigo de autorizacion invalido",
        20: "Codigo de autorizacion expirado",
        21: "Fraude - Reverso pendiente",
        22: "Codigo de autorizacion invalido - Reverso pendiente",
        23: "Codigo de autorizacion expirado - Reverso pendiente",
        24: "Fraude - Reverso solicitado",
        25: "Codigo de autorizacion invalido - Reverso solicitado",
        26: "Codigo de autorizacion expirado - Reverso solicitado",
        27: "Comercio - Reverso pendiente",
        28: "Comercio - Reverso solicitado",
        29: "Annulled",
        30: "Asentada en archivo de cierre",
        31: "En espera de OTP",
        32: "OTP validado exitosamente",
        33: "OTP no validado",
        34: "Reverso parcial",
        35: "3DS method requested, waiting to continue",
        36: "3DS challenge requested, waiting CRES",
        37: "Rejected by 3DS",
        38: "In Dispute blacklisted",
        39: "In Dispute notified",
        40: "Chargeback applied",
        41: "Paid carrier",
        42: "Settled",
        43: "Invoiced",
        44: "Refund applied",
        45: "Partial refund applied",
        46: "Refund or Chargeback not notified",
        47: "Failure cpf validation",
        48: "Autenticado en 3DS",
        999: "Default"
    },
    },
    reports: {
      title: "Reportes",
      description: "Historial de reportes generados",
      disclaimer:
        "* Se recomienda de usar una herramienta de descompresión diferente a la que viene incluida en su equipo, se recomienda usar  ",
      disclaimerA: "The Unarchiver",
      disclaimerA1: "(MacOS)",
      disclaimerB: "Winrar",
      disclaimerB1: "(Windows)",
      columns: {
        id: "Product ID",
        name: "Reporte",
        date: "Fecha",
        user: "Solicitado por",
        status: "Estado",
        action: "Acción",
        password: "Password",
      },
      pie: "Mostrando %{start} a %{to} de %{total} reportes solicitados",
      download: "Descargar",
      detail: "Detalle",
      status: {
        error: "En error",
        ok: "Listo",
        tooLong: "Demasiado grande",
        inProgress: "En proceso",
      },
    },
    export: {
      title: "Exportar reporte",
      reportName: "Nombre del reporte",
      separator: "Separador",
      columns:
        "Si marca esta casilla el reporte saldrá con todas las columnas, en caso contrario solo se exportara con las columnas seleccionadas en la consulta.",
      aviso1:
        "Si el reporte contiene un gran volumen de información podría tardar algunos minutos en crearse.",
      export: "Exportar",
      return: "Volver",
      createTitle: "Creando reporte",
      status: {
        error: "Ocurrió un problema al generar el reporte ",
        ok: "Tú reporte se generó exitosamente",
        tooLong:
          "Tu reporte está en proceso de creación, puedes ver el estado del reporte y descargarlo cuando esté listo en la página Reportes.",
      },
      returnReports: "Ir a Reportes",
    },
    refund: {
      title: "Reembolsar",
      button: "Reembolsar",
      buttonYes: "Si, reembolsar",
      buttonNo: "No, volver",
      total: "Reembolso total",
      partial: "Reembolso parcial",
      partialInputLabel: "Ingrese la cantidad a reembolsar",
      msg: {
        warning: "Se reembolsará la cantidad de %{amount}.",
        question: "¿Estás seguro de reembolsar esta transacción?",
        chooseType: "¿Qué tipo de reembolso desea realizar?",
        maxPartialAmount: "El máximo posible es %{amount}.",
        partialRefundError:
          "El campo debe ser menor a %{max} y mayor a %{min}.",
        ok: "El reembolso se realizó con éxito ",
        error: "No se puedo realizar el reembolso",
        errorPartialRefund: "Carrier no acepta reembolso parcial",
      },
    },
    detail: {
      return: "Volver",
      print: "Imprimir",
      status_history:"Históricos de estados",
      label: {
        transaction: "Transacción",
        owner_name: "Owner",
        application_code: "App code",
        carrier_tr_id: "Id transacción",
        transaction_status: "Estado transacción",
        status: "Estado",
        status_detail: "Detalle de estado",
        created_at: "Fecha de creación",
        payment_date: "Fecha Autorización",
        updated_at: "Fecha de última actualización",
        dev_reference: "Número de orden",
        product_description: "Descripción del producto",
        amount: "Monto",
        vat: "Impuesto",
        discount: "Descuento",
        usd_amount: "Monto en USD",
        installments: "Cuotas",
        installments_type: "Tipo de cuotas",
        refund_amount: "Monto devolución",
        MONTODEDEVOLUCION: "MONTODEDEVOLUCION",
        payment: "Pago",
        payment_method_type: "Método de pago",
        carrier: "Carrier",
        commerce_code: "Código del comercio / Convenio PSE",
        card_type: "Franquicia",
        lote: "Lote",
        trace_number: "Trace Number",
        pse_cycle: "Ciclo",
        card_bin: "Bin de la tarjeta",
        card_number: "Tarjeta",
        card_bank_name: "Banco",
        bank_code: "Código del Banco",
        bank_name: "Banco",
        terminal_code: "Terminal",
        ENTIDAD: "ENTIDAD",
        authorization_code: "Cód. de autorización",
        carrier_code: "Cód. de respuesta",
        message: "Mensaje",
        user: "Usuario",
        user_id: "ID usuario",
        buyer_full_name: "Nombre y apellido",
        buyer_email: "Correo",
        TELEFONO: "TELEFONO",
        buyer_fiscal_number: "Identificación",
        DIRECCION_IP: "DIRECCION IP",
        d: "DOMICILIO FACTURACION",
        c: "CIUDAD FACTURACIÓN",
        d2: "DOMICILIO ENTREGA",
        c2: "CUIDAD ENTREGA",
        autentication: "Autenticación",
        commerce: "Comercio",
        buy: "Compra",
        three_ds: {
          status: "Estado",
          return_code: "Código de respuesta",
          return_message: "Mensaje",
          version: "Versión",
          xid: "XID",
          cavv: "CAVV",
          eci: "ECI",
        },
        address_billing: {
          address: "Domicilio de facturación",
          city: "Ciudad de facturación",
        },
        address_shipping: {
          address: "Domicilio de entrega",
          city: "Ciudad de entrega",
        },
        antifraud_title: "Motor Antifraude",
        antifraud: {
          decision: "Respuesta",
          risk_score: "Score de riesgo",
          rules_triggered: "Reglas aplicadas",
          system: "Sistema",
        },
      },
    },
    log: {
      button: "Ver logs",
      noData: "Sin información ",
    },
    filters: {
      simpleFilters: "Seleccione un filtro e ingrese un valor",
      multiFilters:
        "Puedes hacer múltiples búsquedas sepando los valores con comas ','",
      title: "Filtros",
      error: {
        vacio: "No hay datos validos",
        multiple: "No se puede ingresar mas de un valor para este filtro",
        email: "Datos con formato incorrecto",
      },
      authCode: "Cód. Auth",
      userName: "Nombre",
      userEmail: "Correo",
      status: "Estado",
      id: "Transaction ID",
      cardType: "Franquicia",
      cardBin: "Bin",
      cardNumber: "Tarjeta",
      devReference: "Número de orden",
      userDashboard: "Usuario",
      carrierCode: "Cód de respuesta",
      userId: "Id usuario",
      updated_at: "Actualización",
      created_at:"Creación",
      payment_date:"Pago"
    },
    status: {
      ok: "Aprobada",
      error: "Rechazada",
      pending: "Pendiente",
      title: "Estado de los servicios",
      description: "Estado de los servicios",
      columns: {
        name: "Servicio",
        status: "Estado",
        description: "Detalles",
      },
      pie: "Mostrando %{start} a %{to} de %{total} registros",
    },
    linktopay: {
      search: {
        titleTable: "Historial de linkToPay",
      },
      title: "Creación de Link de pago",
      titleRdb: "Link de pago",
      tabManual: "Carga Manual",
      tabFile: "Cargar archivo",
      label: {
        point: "Punto (App code)",
        idUser: "ID de usuario",
        nameClient: "Nombre de cliente",
        lastnameClient: "Apellidos del cliente",
        nameClientRdb: "A quien le envías el link",
        lastnameClientRdb: "Apellidos del cliente",
        emailClient: "Correo electrónico del cliente",
        exampleEmail: "correo@electronico.com",
        exampleEmailRdb: "abc@abc.com (a donde llegará el link de pago)",
        phone: "Teléfono fijo o celular del cliente",
        prhoneRdb: "Teléfono fijo o Celular de su cliente",
        phonePlaceholderRdb: "No incluyas espacios – si es fijo incluye indicativo",
        numOrder: "Número de orden",
        numOrderRdb: "Número de factura o referencia",
        descripOrder: "Descripción de la orden",
        descripOrderRdb: "Incluye información relevante de la compra - Max 200 caracteres ",
        amountOrder: "Monto antes de impuesto",
        amountOrderRdb: "Monto base de la cuenta sin impuestos",
        iva: "IVA", 
        tax: "Impuesto",
        no: "No",
        yes: "Si",
        acceptFees: "Aceptan cuotas",
        partialPayments: "Pagos parciales",
        totalPay: "Valor total a pagar",
        totalPayRdb: "Valor total de la venta",
        linkExpiration: "Expiración del link",
        linkExpirationRdb: "Expiración del link",
        successUrl: "Url transacción exitosa",
        rejectUrl: "Url transacción fallida",
        pendingUrl: "Url transacción pendiente",
        revisionUrl: "Url transacción en revisión",
        emailUser: "Email de usuario",
        nameUser: "Nombre de usuario",
        fiscalNumber: "Tipo y número de identificación (opcional)",
        fiscalNumberPlaceholder: "Número de identificación",
      },
      tooltip: {
        rule1: "No incluyas espacios.",
        rule2: "Si es fijo incluye indicativo.",
        rule3: "Debe contener mínimo 10 digitos.",
      },
      text: {
        title: "Generación del link",
        description:
          "Para crear un link de pago manualmente debes ingresar la información del formulario a continuación.",
        descriptionRdb: "Generar un nuevo Link de Pagos",
        redirect: "Redireccionamiento",
        additionalInfo: "Información adicional",
        failureData:
          "No ha sido posible generar el link. Por favor revisa los datos y vuelve a intentarlo o contacta con alguien de operaciones",
        titleModalLoading: "Procesando",
        titleModalSuccess: "Captura exitosa",
        titleModalFailure: "Captura fallida",
        bodyModalSuccess:
          "El link fue generado con éxito. Puedes consultarlo en: ",
        bodyModalFailure: "Verifica los datos",
        copySuccess: "¡Copiado!",
        ok: "! Listo ¡",
        mensajeListo:
          "Tu nuevo link de pago ha sido generado exitosamente y se envío el correo electrónico a tu cliente",
        mensaje: "Continúa moviendo tu negocio hasta tus clientes",
        nuevo: "Generar un nuevo link",
        mensajeLink:
          "Para enviarlo, copia este link para que tus clientes puedan recibirlo",
        msajeRedes: "Envíalo a través de tus redes sociales",
        titleDetails: "Información del Link de pago",
        createdAt: "Fecha de creación",
        emailUser: "Correo",
        mind:"Ten en cuenta",
        clientMail:"Tu cliente recibirá un correo de Pagos Redeban para ver un ejemplo haga clic ",
        clientSpam: "El correo podría llegar a la bandeja de correo no deseado.",
        here: "Aquí"
      },
      fiscalNumberTypes: {
        cc: "CC (Cédula de ciudadanía)",
        ce: "CE (Cédula de extranjería)",
        nit: "NIT (Número de identificación tributario)",
        ti: "TI (Tarjeta de identidad)",
        pp: "PP (Pasaporte)",
        idc: "IDC (Identificador único de cliente)",
        cel: "CEL",
        rc: "RC (Registro civil de nacimiento)",
        de: "DE (Documento de identificación extranjero)",
      },
      tabs: {
        manually: "Crear manualmente",
        uploadFile: "Cargar archivo .csv",
      },
      button: {
        createLink: "Continuar",
        createLinkRdb: "Crear link",
        clearData: "Limpiar datos",
        closeModal: "Volver",
      },
      file: {
        uploadSuccess: "Carga exitosa",
        uploadError: "Error en la carga",
        creatingLinks: "Creando de links de pago",
        linksSuccess: "La carga masiva de Links de Pago ha sido exitosamente",
        linksErrors: "Revisa los datos ingresados",
        formatLinkToPay: "Formato Link de pago",
        exampleFile: "Ejemplo archivo",
        preview: "Previsualización",
        reference: "Número de orden",
        customerName: "Nombre Cliente",
        customerLastName: "Apellido Cliente",
        email: "Correo",
        phone: "Teléfono",
        description: "Descripción",
        amount: "Monto base",
        tax: "Impuesto",
        expirationDate: "Fecha de expiración",
        clear: "Limpiar",
        uploadFile: "Cargar archivo",
        closeModal: "Cerrar",
        id_user: "Id de usuario",
        labelInput: "Archivo factura"
      },
      feedback:{
        required: "Este campo es obligatorio",
        email: "Escribe un correo electrónico válido",
        minphone: "Debe contener mínimo 10 digitos",
        maxphone: "Debe ser menor o igual a 15 digitos",
        positive: "Debe ser un número positivo",
        min: "Debe ser mayor o igual a 0",
        min1: "Debe ser mayor o igual a 1",
      },
      columns: {
        CreatedAt: "Creado",
        user_email: "Email",
        user_name: "Nombre",
        user_last_name: "Apellidos",
        order_dev_reference: "Número de orden",
        order_amount: "Monto",
        payment_url: "Url de pago",
        config_expiration_date: "Fecha de expiración",
        status: "Estado",
        payment_reference: "Referencia de pago",
        details: "Ver detalle",
        user_creator: "Usuario creador",
        detail_error: "Detalle del error",
        id_link_to_pay: "ID Link de pago",
        accept_terms: "Aceptó términos",
      },
    },
    calendar:{
      format: "DD/MM/YYYY HH:mm",
      applyLabel: "Aplicar",
      cancelLabel: "Cancelar",
      fromLabel: "De",
      toLabel: "A",
      customRangeLabel: "Personalizado" ,
      daysOfWeek:{
        su: "Do",
        mo: "Lu",
        tu: "Ma",
        we: "Mi",
        th: "Ju",
        fr: "Vi",
        sa: "Sa",
      },
      monthNames:{
        jan: "Enero",
        feb: "Febrero",
        mar: "Marzo",
        apr: "Abril",
        may: "Mayo",
        jun: "Junio",
        jul: "Julio",
        ago: "Agosto",
        sep: "Septiembre",
        oct: "Octubre",
        nov: "Noviembre",
        dec: "Diciembre"
      }
    }
  },
});
