import { all, takeEvery, put as putAction, call, select } from "redux-saga/effects";

import { ACTION_TYPES } from "../../constant/actionTypes";
import { fecthLogin, fecthResetPassword, fecthChangePassword, fetchCurrentAccount, fetchPaymentsType, fetchPaymentsErrorType, fetchRefreshToket } from "../../services/authService";
import { authLoading, authLogin } from "./authActions";
import { authData } from "./authFilter";
//import { messagesError } from "../messages/messagesActions";
import { translate } from "react-i18nify";
import { push } from 'connected-react-router';
import { getGlobalValue } from "../../../context/langContext";

export const getAuth = (state) => state.auth;

export function* LOGIN_SAGA({ payload, conf }) {
  const connectionLanguage = getGlobalValue();
  const { email, password,  } = payload;
  const setSubmitting = conf.setSubmitting || (x => x);
  yield putAction(authLoading(true));
  try {
    const success = yield call(fecthLogin, { email, password}, connectionLanguage );
    const user = yield call(authData, success.data.token);
    yield putAction(authLogin({ user: user }));
    yield putAction(authLogin(success.data));
    let info = yield call(fetchCurrentAccount, success.data.token);
    if (info.data && info.data.Permission){
      info.data.isRefund = hashPermissions(info.data.Permission, "refund");
      info.data.isAntifraud = hashPermissions(info.data.Permission, "antifraud");
    }
    yield putAction(authLogin(info.data));
    const payment = yield call(fetchPaymentsType, success.data.token);
    yield putAction(authLogin({ payments: payment.data }));
    const paymentError = yield call(fetchPaymentsErrorType, success.data.token);
    yield putAction(authLogin({ paymentsError: paymentError.data }));
  } catch (error) {
    //yield putAction(messagesError(error));
    yield putAction(authLoading(false));
    if (error.response && error.response.data && error.response.data.message) {
      conf.setErrors({
        "email": error.response.data.message,
        "password": error.response.data.message
      })
    } else {
      conf.setErrors({
        "email": translate("error_messages.unauthorized"),
        "password": translate("error_messages.unauthorized")
      })
    }
  }
  setSubmitting(false);
}
function hashPermissions(permisos, permiso) {
  if(permisos && permisos.length > 0) {
    const lista = permisos.split(",")
    return lista.includes(permiso) ? 1 : 0
  }
  return 0
}
export function* REFRESH_TOKEN_SAGA() {
  try {
    const auth = yield select(getAuth);
    const success = yield call(fetchRefreshToket, auth.token);
    yield putAction(authLogin(success.data));
  } catch (error) {

  }
}

export function* RESET_PASSWORD_SAGA({ payload, conf }) {
  const connectionLanguage = getGlobalValue();
  const { email, token } = payload;
  const setSubmitting = conf.setSubmitting || (x => x);
  setSubmitting(true);
  try {
    yield call(fecthResetPassword, { email, token}, connectionLanguage  );
    setSubmitting(false);
    yield putAction(push('/reset-password-send'));
  } catch (error) {
    conf.setErrors({
      "email": error.response.data.Msg
    })
    setSubmitting(false);
  }
  setSubmitting(false);
}

export function* CHANGE_PASSWORD_SAGA({ payload, conf }) {
  const connectionLanguage = getGlobalValue();
  const { identifier, password } = payload;
  const setSubmitting = conf.setSubmitting || (x => x);
  setSubmitting(true);
  try {
    yield call(fecthChangePassword, { identifier, password },connectionLanguage );
    setSubmitting(false);
    yield putAction(push('/user-reset-password-ok'));
  } catch (error) {
    conf.setErrors({
      "password": error.response.data.Msg
    })
    setSubmitting(false);
  }
  setSubmitting(false);
}

/*
export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true
    }
  });
  const response = yield call(currentAccount);
  if (response) {
    const { uid: id, email, photoURL: avatar } = response;
    yield put({
      type: "user/SET_STATE",
      payload: {
        id,
        name: "Administrator",
        email,
        avatar,
        role: "admin",
        authorized: true
      }
    });
  }
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: false
    }
  });
}

export function* LOGOUT() {
  yield call(logout);
  yield put({
    type: "user/SET_STATE",
    payload: {
      id: "",
      name: "",
      role: "",
      email: "",
      avatar: "",
      authorized: false,
      loading: false
    }
  });
}*/

export default function* AuthSaga() {
  yield all([
    takeEvery(ACTION_TYPES.AUTH.ASYNC.LOGIN, LOGIN_SAGA),
    takeEvery(ACTION_TYPES.AUTH.ASYNC.RESET_PASSWORD, RESET_PASSWORD_SAGA),
    takeEvery(ACTION_TYPES.AUTH.ASYNC.CHANGE_PASSWORD, CHANGE_PASSWORD_SAGA),
    takeEvery(ACTION_TYPES.AUTH.ASYNC.REFRESH_TOKEN, REFRESH_TOKEN_SAGA)
    //takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    //takeEvery(actions.LOGOUT, LOGOUT),
    //LOAD_CURRENT_ACCOUNT() // run once on app load to check user auth
  ]);
}
